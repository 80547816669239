import {
  Configuration,
  LogLevel,
  RedirectRequest,
  PublicClientApplication,
  IPublicClientApplication,
  AccountInfo
} from '@azure/msal-browser';
import { globalEndpoints } from '../app/constants/global.constants'

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const B2C_POLICIES = {
  names: {
    signUpSignIn: 'B2C_1_sign_in'
  },
  authorities: {
    signUpSignIn: {
  authority: `${globalEndpoints.authorityDomain}/${globalEndpoints.tenantId}/B2C_1_sign_in`
    }
  },
  authorityDomain: globalEndpoints.authorityDomain
};

//'https://trustnetplatformdev.b2clogin.com/trustnetplatformdev.onmicrosoft.com/B2C_1_sign_in'
//https://trustnetplatformdev.b2clogin.com/trustnetplatformdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_sign_in&client_id=f4dc84a0-755d-418e-ab88-518df0ec1b15&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fproud-ocean-059af3003.5.azurestaticapps.net%2F&scope=openid&response_type=id_token&prompt=login
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
const { NODE_ENV } = process.env;
export const msalConfig: Configuration = {
  auth: {
    clientId: globalEndpoints.clientId,
    authority: B2C_POLICIES.authorities.signUpSignIn.authority,
    knownAuthorities: [B2C_POLICIES.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: globalEndpoints.app,
    postLogoutRedirectUri: 'https://login.microsoftonline.com/common/oauth2/logout', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message)
            return;
          case LogLevel.Info:
            // console.info(message)
            return;
          case LogLevel.Verbose:
            // console.debug(message)
            return;
          case LogLevel.Warning:
            // console.warn(message)
            return;
          case LogLevel.Trace:
            return;
        }
      }
    }
  }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: RedirectRequest = {
  scopes: ['openid']
};

export const getToken = async (msalInstance: PublicClientApplication): Promise<string> => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
  }

  const request = {
    scopes: loginRequest.scopes,
    account: activeAccount || accounts[0]
  };

  const authResult = await msalInstance.acquireTokenSilent(request);

  return authResult.idToken;
};

export const logoutUser = (instance: IPublicClientApplication, accounts: AccountInfo[]) => {
  instance.logoutRedirect({
    account: accounts[0],
    postLogoutRedirectUri: msalConfig.auth.redirectUri
  });
};
