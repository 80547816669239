import React,{ Fragment, useEffect } from 'react';
import _ from 'lodash';

import { useAppSelector, useAppDispatch } from 'hooks/redux.hooks';
import { fetchIncidents } from 'services/incidents.services';
import { fetchCompanyUsers } from 'services/company.services';
import * as THEME from 'components/Layout/theme';
import { fetchTasks } from 'services/tasks.services';
import { OpenIncident } from './OpenIncident';
import { RightSide } from './RightSide';
import { LeftSide } from './LeftSide';

export const ControlRoom: React.FC = () => {
    const dispatch = useAppDispatch();
    const sinceDay = useAppSelector((state) => state.ui.controlRoom.sinceDaysAgo);
    const untilDay = useAppSelector((state) => state.ui.controlRoom.untilDaysAgo);

    useEffect(() => {
        dispatch(fetchIncidents(sinceDay, untilDay));
        dispatch(fetchTasks(sinceDay, untilDay));
        dispatch(fetchCompanyUsers());
    }, [sinceDay]);

    return (
        <Fragment>
            <OpenIncident/>
            <THEME.TWO_COLUMNS
                page='controlRoom'
                LeftSide={LeftSide}
                RightSide={RightSide}
                rightSideWidth='wide'
            />
        </Fragment>
    )
}