import React from 'react';


import { rangeNumbersOptions, lang_prefix } from 'constants/ui.constants';
import { IncidentStatus, IncidentSeverity } from 'constants/incident.constants';
import { setConrolRoomSinceDay, setOwner, setSevirirty, setStatus } from "state/features/ui.features";
import { useAppSelector } from 'hooks/redux.hooks';
import { setDropdownOptions, _t } from 'components/helpers';
import { Dropdown } from 'components/Layout/Shared/Dropdown';
import { Greeting } from 'components/Layout/Header/Greeting';

export const Top: React.FC<{ owners: string[], lastUpdate: string }> = ({ lastUpdate, owners }) => {
  const sinceDay = useAppSelector((state) => state.ui.controlRoom.sinceDaysAgo);
  const filter = useAppSelector((state) => state.ui.controlRoom.filter);
  const translation = useAppSelector((state) => state.company.translation);



  return (
    <div className='header'>
      <Greeting
        lastModified={lastUpdate}
        title={_t('live_incidents', translation, lang_prefix.API_TITLE)}
      />
      <div className='filter'>
        <Dropdown
          name='severity'
          with_all={true}
          onChange={setSevirirty}
          translate={true}
          translate_prefix={lang_prefix.INCIDENTS_SEVIRITY}
          value={{
            value: filter.severity,
            label: _t(filter.severity, translation, lang_prefix.INCIDENTS_SEVIRITY)
          }}
          options={setDropdownOptions(
            [
              IncidentSeverity.CRITICAL,
              IncidentSeverity.HIGH,
              IncidentSeverity.MEDIUM,
              IncidentSeverity.LOW,
              IncidentSeverity.INFO
            ])
          }
        />
        <Dropdown
          name='status'
          with_all={true}
          onChange={setStatus}
          translate={true}
          translate_prefix={lang_prefix.INCIDENTS_STATUS}
          value={{
            value: filter.status,
            label: _t(filter.status, translation, lang_prefix.INCIDENTS_STATUS)
          }}
          options={setDropdownOptions(
            [
              IncidentStatus.CLOSE,
              IncidentStatus.OPEN,
              IncidentStatus.TUNING,
              IncidentStatus.IN_PROGRESS,
              IncidentStatus.WAITING
            ])
          }
        />
        <Dropdown
          name='owner'
          with_all={true}
          onChange={setOwner}
          translate={true}
          translate_prefix={lang_prefix.CONTROLROOM_FILTER}
          value={{
            value: filter.owner,
            label: filter.owner
          }}
          options={setDropdownOptions(owners)}
        />
        <Dropdown
          onChange={setConrolRoomSinceDay}
          translate={true}
          translate_prefix={lang_prefix.API_FILTER_DAYS}
          value={{
            value: sinceDay,
            label: _t(sinceDay, translation, lang_prefix.API_FILTER_DAYS)
          }}
          options={setDropdownOptions([
            rangeNumbersOptions.LAST_3,
            rangeNumbersOptions.LAST_7,
            rangeNumbersOptions.LAST_14,
            rangeNumbersOptions.LAST_30,
            rangeNumbersOptions.LAST_90,
          ])}
        />
      </div>
    </div>
  )
}