import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { 
  activeTabs,
  defaultIncidentColumns,
  Incident,
  IncidentEnum,
  reportsTabs,
  IncidentsClosingReason,
  IncidentStatus 
} from 'constants/incident.constants';
import { TaskEnum } from 'constants/tasks.constants';
import { orderBy } from 'constants/ui.constants';
import { endOfDay, startOfDay, subDays } from 'date-fns';

interface IModalConfig {
  title?: string;
}

interface IUiState {
  isModalOpen: boolean;
  modalConfig?: IModalConfig;
  isNavBarOpen: boolean;
  page: string;
  darkMode: boolean;
  sinceDaysAgo: string;
  untilDaysAgo: string;
  navHeader: {
    popupDisplay: boolean;
  };
  controlRoom: {
    filter: {
      owner: string;
      status: string;
      severity: string;
    };
    activeIncident: {
      active: boolean;
      tab: activeTabs;
      incident: Incident | null;
    };
    sort: {
      name: TaskEnum;
      orderByState: orderBy;
    };
    incidentSort: {
      name: IncidentEnum;
      orderByState: orderBy;
    };
    sinceDaysAgo: string;
    untilDaysAgo: string;
  };
  reports: {
    filter: {
      [IncidentEnum.SEVERITY]: string;
      [IncidentEnum.STATUS]: string;
      [IncidentEnum.OWNER]: string;
      [IncidentEnum.TASKS]: string;
      [IncidentEnum.HOST_NAME]: string;
      [IncidentEnum.CLOSING_REASON]: string;
      start_date: string;
      end_date: string;
    };
    sort: {
      name: IncidentEnum;
      orderByState: orderBy;
    };
    sinceDaysAgo: string;
    untilDaysAgo: string;
    columns: IncidentEnum[];
    tab: reportsTabs;
    tasks: {
      showTasks: boolean;
      incidentId: number | null;
    };
    executive: {
      start_date: string;
      end_date: string;
    };
  };
}

export const activeIncidentInitialState = {
  active: false,
  tab: activeTabs.INEVESTIGATION,
  incident: null
};

export const controlRoomSortInitialState = {
  name: TaskEnum.created_at,
  orderByState: orderBy.DESC
};

export const controlRoomSortIncidentInitialState = {
  name: IncidentEnum.CREATED_AT,
  orderByState: orderBy.DESC
};

export const reportsSortInitialState = {
  name: IncidentEnum.CREATED_AT,
  orderByState: orderBy.DESC
};

export const reportsTasksInitialState = {
  showTasks: false,
  incidentId: null
};

export const reportsFilterInitialState = {
    [IncidentEnum.SEVERITY]: 'all_' + IncidentEnum.SEVERITY,
    [IncidentEnum.STATUS]: 'all_' + IncidentEnum.STATUS,
    [IncidentEnum.OWNER]: 'all_' + IncidentEnum.OWNER,
    [IncidentEnum.HOST_NAME]: 'all_' + IncidentEnum.HOST_NAME,
    [IncidentEnum.TASKS]: 'all_' + IncidentEnum.TASKS,
    [IncidentEnum.CLOSING_REASON]: 'all_' + IncidentEnum.CLOSING_REASON,
    start_date: String(subDays(startOfDay(new Date()),3)),
    end_date: String(endOfDay(new Date()))
};

const initialState: IUiState = {
  isModalOpen: false,
  isNavBarOpen: false,
  page: 'dashboard',
  darkMode: false,
  sinceDaysAgo: '3',
  untilDaysAgo: '0',
  navHeader: {
    popupDisplay: false
  },
  controlRoom: {
    filter: {
      [IncidentEnum.OWNER]: 'all_' + IncidentEnum.OWNER,
      [IncidentEnum.STATUS]: 'all_' + IncidentEnum.STATUS,
      [IncidentEnum.SEVERITY]: 'all_' + IncidentEnum.SEVERITY
    },
    sort: controlRoomSortInitialState,
    incidentSort: controlRoomSortIncidentInitialState,
    activeIncident: activeIncidentInitialState,
    sinceDaysAgo: '7',
    untilDaysAgo: '0'
  },
  reports: {
    filter: reportsFilterInitialState,
    executive: {
      start_date: String(subDays(startOfDay(new Date()),3)),
      end_date: String(endOfDay(new Date()))
    },
    sort: reportsSortInitialState,
    sinceDaysAgo: 'All',
    untilDaysAgo: '0',
    columns: defaultIncidentColumns,
    tab: reportsTabs.EXPORT_EXCEL,
    tasks: reportsTasksInitialState
  }
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    togglePage: (state, action: PayloadAction<string>) => {
      state.page = action.payload;
    },
    toggleMode: (state) => {
      state.darkMode = !state.darkMode;
    },
    setSinceDay: (state, { payload }) => {
      state.sinceDaysAgo = payload;
    },
    setOwner: (state, { payload }) => {
      state.controlRoom.filter.owner = payload;
    },
    setStatus: (state, { payload }) => {
      state.controlRoom.filter.status = payload;
    },
    setSevirirty: (state, { payload }) => {
      state.controlRoom.filter.severity = payload;
    },
    setConrolRoomActiveIncident: (state, { payload }) => {
      state.controlRoom.activeIncident = payload;
    },
    setControlRoomSortIncidents: (state, { payload }) => {
      state.controlRoom.incidentSort = payload;
    },
    setConrolRoomSinceDay: (state, { payload }) => {
      state.controlRoom.sinceDaysAgo = payload;
    },
    setConrolRoomSortTasks: (state, { payload }) => {
      state.controlRoom.sort = payload;
    },
    setControlRoomIncidentsTasks: (state, { payload }) => {
      state.controlRoom.incidentSort = payload;
    },
    setPopupDisplay: (state) => {
      state.navHeader.popupDisplay = !state.navHeader.popupDisplay;
    },
    toggleShowModal: (state, { payload }) => {
      const modal = document.getElementById(`${payload}`);
      modal?.classList.toggle('show');
    },
    setReportsSort: (state, { payload }) => {
      state.reports.sort = payload;
    },
    setReportsFilter: (state, { payload }) => {
      state.reports.filter[payload.name] = payload.value;
    },
    setReportsFalsePositiveFilter: (state) => {
      state.reports.filter.start_date = String(subDays(new Date(),Number(state.sinceDaysAgo)));
      state.reports.filter.closing_reason = IncidentsClosingReason.FALSE_POSITIVE_RATE;
      state.reports.filter.status = IncidentStatus.CLOSE;
    },
    setReportsAllFilters: (state, { payload }) => {
      state.reports.filter = payload;
    },
    setReportsExecutiveFilter: (state, { payload }) => {
      state.reports.executive[payload.name] = payload.value;
    },
    setReportsColumns: (state, { payload }) => {
      state.reports.columns = _.xor(state.reports.columns, [payload]);
    },
    setReportsTab: (state, { payload }) => {
      state.reports.tab = payload;
    },
    setReportsTasks: (state, { payload }) => {
      state.reports.tasks = payload;
    }
  }
});

export const {
  togglePage,
  toggleMode,
  setSinceDay,
  setOwner,
  setSevirirty,
  setStatus,
  setConrolRoomActiveIncident,
  setControlRoomSortIncidents,
  setConrolRoomSinceDay,
  setConrolRoomSortTasks,
  setReportsSort,
  setReportsFilter,
  setReportsAllFilters,
  setReportsFalsePositiveFilter,
  setReportsColumns,
  setReportsTab,
  setReportsExecutiveFilter,
  setPopupDisplay,
  toggleShowModal,
  setReportsTasks
} = uiSlice.actions;

export default uiSlice.reducer;
