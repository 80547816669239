import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import { EditText, EditTextarea } from 'react-edit-text';

import { TasksCls } from 'class/class.tasks';
import { fetchIncidentStatusUpdates, updateIncident } from 'services/incidents.services';
import { lang_prefix, controlRoomMaxInfoBox } from 'constants/ui.constants';
import { IncidentEnum, activeTabs, activeIncidentProp, readonlyIncidentProp } from 'constants/incident.constants';
import { useAppSelector,useAppDispatch } from 'hooks/redux.hooks';
import { Button, ParagraphText } from 'components/Layout/Shared/Elements';
import * as THEME from 'components/Layout/theme';
import { IncidentItem } from './IncidentItem';
import { Accordion } from 'components/Layout/Shared/Accordion';
import { setConrolRoomActiveIncident, activeIncidentInitialState } from 'state/features/ui.features';
import * as TAB from 'components/Layout/Shared/Tabs';
import { GraphsCls } from 'class/class.graphs';
import { CHART } from './Charts';
import { TasksList } from './TasksList';
import { _t } from 'components/helpers';
import { userRoles } from 'state/features/global.features';
import Timeline from 'components/Layout/Shared/Timeline';

export const OpenIncident: React.FC = () => {
    const activeIncident = useAppSelector((state) => state.ui.controlRoom.activeIncident);
    const dispatch = useAppDispatch();
    useEffect(()=>{
        if(activeIncident?.incident?.id){
            dispatch(fetchIncidentStatusUpdates(activeIncident?.incident?.id))
        }
    }, [activeIncident])
    return (
        <Accordion
            style='gallery-item'
            active={activeIncident.active}
            activeAction={() => {
                dispatch(setConrolRoomActiveIncident(activeIncidentInitialState)) }}
            >
            <THEME.TWO_COLUMNS
                page='no-border'
                LeftSide={IncidentCard}
                RightSide={Fragment}
                rightSideWidth='wide'
            />
            <THEME.TWO_COLUMNS
                page='no-border top-padding'
                LeftSide={Gallery}
                RightSide={RightSide}
                rightSideWidth='wide'
            />
        </Accordion>
    )
}
const IncidentCard: React.FC = () => {
    const incident = useAppSelector((state) => state.ui.controlRoom.activeIncident.incident);
    if(!incident){ return <Fragment></Fragment> };

    return <IncidentItem isSelected={false} item={incident} activeTitle={false}/>
}

const Gallery: React.FC = () => {
    const incidents = useAppSelector((state) => state.incidents.items);
    const incidentStatusChanges = useAppSelector((state) => state.incidents.incidentStatus);
    const [infoBoxesAmount, setInfoBoxesAmount] = useState(controlRoomMaxInfoBox);
    const incident = useAppSelector((state) => state.ui.controlRoom.activeIncident.incident);
    const dispatch = useAppDispatch();
    const openedTab = useAppSelector((state) => state.ui.controlRoom.activeIncident.tab);
    const translation = useAppSelector((state) => state.company.translation);
    const activeIncident = useAppSelector((state) => state.ui.controlRoom.activeIncident);
    const userRole = useAppSelector((state) => state.global.userRole);
    const graphsCls = new GraphsCls();
    graphsCls.setIncidents = incidents;
    const translations = _t(activeIncidentProp, translation, lang_prefix.INCIDENTS_PROPERTY);

    if(!incident){ return <Fragment></Fragment>}
    return  (
        <Fragment>
            <div className='board'>
                <TAB.TABS_HEADER style='line'>
                    <TAB.LINE_BUTTON_TAB
                        tab={{
                            key:activeTabs.INEVESTIGATION,
                            title:activeTabs.INEVESTIGATION,
                            isActive:TAB.isActive([activeTabs.INEVESTIGATION],openedTab)
                        }}
                        toggle={() => {
                            dispatch(setConrolRoomActiveIncident({...activeIncident, tab:activeTabs.INEVESTIGATION})) }}
                    />
                    <TAB.LINE_BUTTON_TAB
                        tab={{
                            key:activeTabs.CHART,
                            title:activeTabs.CHART,
                            isActive:TAB.isActive([activeTabs.CHART],openedTab)}}
                        toggle={() => {
                            dispatch(setConrolRoomActiveIncident({...activeIncident, tab:activeTabs.CHART})) }}
                    />
                </TAB.TABS_HEADER>

                <TAB.TAB_CONTAINER style='flex-columns' isActive={TAB.isActive([activeTabs.INEVESTIGATION],openedTab)}>
                <div className='inner-container-investigation'>
                    <ParagraphText style='flex-columns-max description'>
                        <EditTextarea
                            readonly={_.isEqual(userRole,userRoles.USER)}
                            name={IncidentEnum.INVESTIGATION}
                            defaultValue={incident.investigation}
                            placeholder='n/a'
                            rows={5}
                            className='editable-textarea control-room-textarea-close'
                            inputClassName='editable-textarea control-room-textarea-open'
                            onSave={(editText) => {dispatch(updateIncident(editText, incident.id))}}               
                        />
                    </ParagraphText>
                    <div className='timeline'>
                       {incidentStatusChanges?<Timeline items={incidentStatusChanges}/>:""}
                </div>
                </div>

                </TAB.TAB_CONTAINER>
               {openedTab===activeTabs.INEVESTIGATION && 
               <div className='footer'>
               {
                   activeIncidentProp.map((infoProp:IncidentEnum,index:number) =>
                       <Fragment key={index}>
                       {(index < infoBoxesAmount) && <InfoBox
                           property={infoProp}
                           title={translations[infoProp]}
                           text={incident[infoProp] || ''}
                           id={incident.id}
                           readonly={_.isEqual(userRole,userRoles.USER) || _.includes(readonlyIncidentProp,infoProp)}
                       />}
                       </Fragment>                                
                   )
               }
               <Button 
                   callback={() => {
                       const amount = infoBoxesAmount === activeIncidentProp.length ? 
                       controlRoomMaxInfoBox : activeIncidentProp.length;
                       setInfoBoxesAmount(amount); 
                   }} 
                   text={`${(activeIncidentProp.length === infoBoxesAmount) ? '-' : '+' } ${activeIncidentProp.length - controlRoomMaxInfoBox}`}
                   style='more'
               />

           </div>} 
                {graphsCls.incidents.length > 0 &&<TAB.TAB_CONTAINER style='flex-columns' isActive={TAB.isActive([activeTabs.CHART],openedTab)}>
                    <CHART data={graphsCls.groupedIncidentServirity} uniqueDays={graphsCls.getUniqueDays} id={activeIncident?.incident.id} />
                </TAB.TAB_CONTAINER>
                }
            </div>
        </Fragment>
    )
}

export const RightSide: React.FC = () => {
    const selectedIncident = useAppSelector((state) => state.ui.controlRoom.activeIncident.incident);
    const tasks = useAppSelector((state) => state.tasks.items);
    const tasksCls = new TasksCls(tasks);

    if(!selectedIncident){ return <Fragment></Fragment> };
    return <TasksList includeHeader={false} tasks={tasksCls.getById(selectedIncident.id)}/>;
}

export const InfoBox: React.FC<{
    title:any,
    text:string,
    property:IncidentEnum,
    id:number,
    readonly:boolean
}> = ({property, title, text, id, readonly}) => {
    const dispatch = useAppDispatch();
    return (
        <div className='editable-info flex-columns-min'>
            <ParagraphText style='editable-info-title caption grayed'>{title}</ParagraphText>
            <ParagraphText style='editable-info-text regular-text'>
                <EditText
                    inline={true}
                    readonly={readonly}
                    name={property}
                    defaultValue={String(text)}
                    placeholder='n/a'
                    inputClassName='editable-text'
                    onSave={(editText) => {dispatch(updateIncident(editText, id))}}               
                />
            </ParagraphText>
        </div>
    )
}