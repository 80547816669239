import React from 'react';
import { IncidentsCategories, Components } from 'constants/incident.constants';
import { RegularText } from 'components/Layout/Shared/Elements';

const Block = (key:string) => {
    // component does exist
    if (typeof Components[key] !== "undefined") {
      return React.createElement(Components[key]);
    }
    return React.createElement(Components['uncategorized'])
}

export const LONG_CARD: React.FC<{item:any}> = (props):JSX.Element => {
    return <div className={`card rows-long-card sevirity-${props.item?.severity}${props.item?.isSelected ? ' selected':''}`}>
        <div className={`card-headline tooltip incident-icon-${props.item?.category_name}`}>
            <span className="tooltiptext right-tooltip auto-width">
                {
                    IncidentsCategories[props.item?.category_name] ?
                    IncidentsCategories[props.item?.category_name] :
                    IncidentsCategories.uncategorized
                }
            </span>{ Block(props.item?.category_name) }
        </div>
        <div className='card-headline-content'>
            <div className='date-text'>{props.item?.createdDate}</div>
            <RegularText onClick={props.item?.activeTitle} style='text'>{props.item?.title}</RegularText>
            <div className='date-text'>{props.item?.updateDate}</div>
        </div>
        <div className='card-columns'>
            <div>{props.item?.status}<RegularText style='grayed'>{props.item?.reason}</RegularText></div>
            <div>{props.item?.owner}</div>
            {props.item?.tasks}
        </div>
    </div>;
}